import $http from '@/utils/http'

export function doGet(url) {
    return $http.get(url);
}
export function doPost(url, data) {
    return $http.post(url, data);
}



// 首页-数据看板中间数据
export function centerData() {
	return $http.get('/api/phone/data/centerData')
}

//首页-数据看板左侧数据
export function leftData() {
	return $http.get('/api/phone/data/leftData')
}

//首页-数据看板右侧数据
export function rightData() {
	return $http.get('/api/phone/data/rightData')
}
// 支队-实时接口
export function zdListOnlineDevice(id) {
	return $http.get('/api/phone/zd/data/listOnlineDevice?id='+id)
}
// 支队-查询人员数据接口
export function zdSelectNearbyDeviceList(id,latitude,longitude,distance) {
	return $http.get('/api/phone/zd/data/selectNearbyDeviceList?id='+id+'&latitude='+latitude+'&longitude='+longitude+'&distance='+distance)
}
// 分局-实时接口
export function fjListOnlineDevice(id) {
	return $http.get('/api/phone/fj/data/listOnlineDevice?id='+id)
}
// 分局-查询人员数据接口
export function fjSelectNearbyDeviceList(id,latitude,longitude,distance) {
	return $http.get('/api/phone/fj/data/selectNearbyDeviceList?id='+id+'&latitude='+latitude+'&longitude='+longitude+'&distance='+distance)
}
// 派出所-实时接口
export function pcsListOnlineDevice(id) {
	return $http.get('/api/phone/pcs/data/listOnlineDevice?id='+id)
}
// 派出所-查询人员数据接口
export function pcsSelectNearbyDeviceList(id,latitude,longitude,distance) {
	return $http.get('/api/phone/pcs/data/selectNearbyDeviceList?id='+id+'&latitude='+latitude+'&longitude='+longitude+'&distance='+distance)
}
// 公司-实时接口
export function gsListOnlineDevice(id) {
	return $http.get('/api/phone/gs/data/listOnlineDevice?id='+id)
}
// 公司-查询人员数据接口
export function gsSelectNearbyDeviceList(id,latitude,longitude,distance) {
	return $http.get('/api/phone/gs/data/selectNearbyDeviceList?id='+id+'&latitude='+latitude+'&longitude='+longitude+'&distance='+distance)
}
// 实时提交据接口
export function sendSms(params) {
	return $http.post('/api/phone/data/sendSms',params)
}

// 支队-数据看板中间数据
export function zdCenterData(id) {
	return $http.get('/api/phone/zd/data/centerData?id='+id)
}

//支队-数据看板左侧数据
export function zdLeftData(id) {
	return $http.get('/api/phone/zd/data/leftData?id='+id)
}

//支队-数据看板右侧数据
export function zdRightData(id) {
	return $http.get('/api/phone/zd/data/rightData?id='+id)
}

// 分局-数据看板中间数据
export function fjCenterData(id) {
	return $http.get('/api/phone/fj/data/centerData?id='+id)
}

//分局-数据看板左侧数据
export function fjLeftData(id) {
	return $http.get('/api/phone/fj/data/leftData?id='+id)
}

//分局-数据看板右侧数据
export function fjRightData(id) {
	return $http.get('/api/phone/fj/data/rightData?id='+id)
}


//派出所-数据看板中间数据
export function pcsCenterData(id) {
	return $http.get('/api/phone/pcs/data/centerData?id='+id)
}

//派出所-数据看板左侧数据
export function pcsLeftData(id) {
	return $http.get('/api/phone/pcs/data/leftData?id='+id)
}

//派出所-数据看板右侧数据
export function pcsRightData(id) {
	return $http.get('/api/phone/pcs/data/rightData?id='+id)
}


//公司-数据看板中间数据
export function gsCenterData(id) {
	return $http.get('/api/phone/gs/data/centerData?id='+id)
}

//公司-数据看板左侧数据
export function gsLeftData(id) {
	return $http.get('/api/phone/gs/data/leftData?id='+id)
}

//公司-数据看板右侧数据
export function gsRightData(id) {
	return $http.get('/api/phone/gs/data/rightData?id='+id)
}